.ql-container.ql-snow {
  height: calc(90vh - 650px);
  min-height: 100px;
  border: solid 1px #d7eee9 !important;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ql-toolbar.ql-snow {
  border: solid 1px #d7eee9 !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.ql-container {
  font-family: 'Poppins', sans-serif !important;
}
.ql-container {
  min-height: 340px !important;
}
