.cell-center {
  text-align: center;
}
.cell-center > * {
  margin: 0 auto;
}

.cell-right {
  text-align: right;
}
.cell-right > * {
  margin: 0 0 0 auto;
}
.cell-3 {
  width: 3%;
}
.cell-5 {
  width: 5%;
}
.cell-6 {
  width: 6%;
}
.cell-7 {
  width: 7%;
}
.cell-8 {
  width: 8%;
}
.cell-9 {
  width: 9%;
}
.cell-10 {
  width: 10%;
}
.cell-12 {
  width: 12%;
}
.cell-13 {
  width: 13%;
}
.cell-15 {
  width: 15%;
}
.cell-17 {
  width: 17%;
}
.cell-20 {
  width: 20%;
}
.cell-25 {
  width: 25%;
}
.cell-30 {
  width: 30%;
}
.cell-35 {
  width: 35%;
}
.cell-40 {
  width: 40%;
}
.cell-45 {
  width: 45%;
}
.cell-50 {
  width: 50%;
}
.cell-55 {
  width: 55%;
}
.cell-60 {
  width: 60%;
}
.cell-100 {
  width: 100%;
}